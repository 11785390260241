@charset 'utf-8';
@import 'settings';
@import 'foundation';
@import 'motion-ui';
@include foundation-global-styles;
@include foundation-grid;
@include foundation-flex-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include motion-ui-transitions;
@include motion-ui-animations;


.igiveup {

	cursor: pointer;
	margin: 10px auto 5px auto;
}

.show { 

	display: block;
}

.reveal-overlay {
	
	background-color: rgba(10, 10, 10, 0.9);
}

.reveal {
	
	.close-button {
	
		display: none;
	}

	.timer,
	#totaliser {

		font-weight: 600;
		border: none;
		border-radius: 5px;
		display: inline-block;
		font-size: 1.2rem;
		margin: 0 0 1.5rem 0;
		padding: 1rem;
		color: #fff;

		span.title {

			color: white;
		}

		div {

			display: inline-block;
			padding: 0;
		}
	}

	.timer {
	
		background:#fd5e60;
	}

	#totaliser {

		margin: 0 0 0 1rem;
		background:#97c82b;
	}

	.giveup {
		
		display: none;
		
		p {

			font-size: 125%;
			margin: 1.5rem;
		}

		p:nth-child(1){
			
			text-transform: uppercase;
			font-weight: 600;
			font-size:125%;
		}

		p:nth-child(2){
			
			text-transform: uppercase;
			font-weight: 600;
			font-size:125%;
		}

		p.yoshi {
	
			text-transform: uppercase;
			font-weight: 600;
			font-size:125%;
			margin-top:0px;
		}
	}

	.complete {
	
		display: none;
		
		p {
			
			font-size: 125%;
			margin: 1.5rem;
		}

		p:nth-child(1){
			
			text-transform: uppercase;
			font-weight: 600;
			font-size: 125%;
			margin: 1.5rem 0 0 0;
		}

		p.bowser{

			text-transform: uppercase;
			margin-top:0px;
			font-weight: 600;
		}
	}

	.intro {
		
		display: block;
	}

	.buttonGo {

		cursor: pointer;
	}

	&:focus {
		
		border:none;
		outline: 0;
	}

	&:active {

		border:none;
		outline: 0;
	}

	@include breakpoint(small) {

		width: 100%;
		padding: 2rem 2rem;
		top: 0rem !important;
		color: white;
		background: none;
		border: none;
		text-align: center;
	}

	@include breakpoint(medium) {
		
		width: 600px;
		max-width: 75rem;
		top: 5rem !important;
		color: white;
		background: none;
		border: none;
		text-align: center;
	}

	p {
		
		margin: 2rem 0;
		font-size: 1.4rem;
		font-weight: 600;
		
	}

	.buttonGo {
	
		margin: 2rem 0;
	}

	p.sub {
		
		margin-top: 0px;
		text-transform: uppercase;
	}
	
	p.link {
		
		font-size: 1.2rem;
		
		a {

			color: white;
			font-weight: bold;
		}
	}
}

.container {
	
	@include breakpoint(small) {
	
		margin: 0 auto;
		position: relative;
		display: block;
	}

	@include breakpoint(medium) {

		width: 98%;
		max-width: 1280px;
		margin: 0 auto;
		position: relative;
	}
}

.card-container {

	@include breakpoint(small) {

		margin-bottom: 86px;
		padding: 0.5rem;
	}

	@include breakpoint(medium) {
	
		margin-bottom: 125px;
		padding: 0;
		
	}
}

header {

	background: #532182;
	display: inline-block;
	width: 100%;
	padding: 0rem 0;

	@include breakpoint(small) {

		margin: 0 0 0rem 0;
	}

	@include breakpoint(medium) {
		
		margin: 0 0 3rem 0;
	}

	.container {
		
		@include breakpoint(small) {
			
			margin:0 auto;
			position: relative;
			display: block;
		}

		@include breakpoint(medium) {
			
			display:flex;
		}
	}

	.logo {

		@include breakpoint(small) {
			
			float:none;
			margin: 0 auto;
			
			img {
	
				width: 100%;
				margin: 0 auto;
				display: block;
				padding: 1rem;
			}
		}

		@include breakpoint(medium) {

			float:left;
			padding: 0rem 0 ;
			box-sizing: content-box;
			margin: 0;
			
			img {
				
				height: 80px;
				width: auto;
			}
		}
	}

	menu {

		padding: 0px;
		margin: 0;
		
		@include breakpoint(small) {
	
			.menu {
				
				padding: 0rem 0 1rem 0;
				margin: 0;
				text-align: center;
				
				ul {
					
					margin: 0px auto;
					padding: 0px;
					display: table;

					li {
						
						height: 100%;
						border-right: 3px solid #8e48a3;
						display: table-cell;
						vertical-align: middle;
						color: #fff;

						&:nth-child(1) {
							
							border-left: 3px solid #8e48a3;
						}

						a {
							
							color: white;
							text-decoration: none;
							text-transform: uppercase;
							font-size: 1rem;
							padding: 0 1.5rem;
						}
					}

					li:nth-child(1) {
						
						border-left: 0;
						
						a {
						
							padding-left: 0;
						}
					}
					
					li:nth-child(2) {
	
						border-right: 0;
						
						a {
							
							padding-right: 0;
						}
					}
				}
			}
		}
		
		@include breakpoint(medium) {

			.menu {

				position: absolute;
				right: 0px;
				height:100%;
				padding: 0px;
				margin: 0;
				
				ul {
					
					margin: 0px;
					padding: 0px;
					display: table;
					width: 100%;
					height: 100%;
					
					li {
						
						height: 100%;
						border-right: 3px solid #8e48a3;
						display: table-cell;
						vertical-align: middle;
						color: #fff;

						&:nth-child(1) {
							
							border-left: 3px solid #8e48a3;
						}

						a {
							
							color: white;
							text-decoration: none;
							text-transform: uppercase;
							font-size: 1.1rem;
							padding: 0 2.5rem;
						}
					}
					
					li:nth-child(1) {
						
						border-left: 3px solid #8e48a3;
					
						a {
							
							padding-left: 2.5rem;
						}
					}
					
					li:nth-child(2) {
						
						border-right: 3px solid #8e48a3;
						
						a {
							
							padding-right: 2.5rem;
						}
					}
				}
			}
		}
	}
}

.card {
	
	position: relative;
	margin: 0 1% 0 0;
	padding: 1rem 1rem;
	height: 450px;
	background-size: 80% !important;
	background-position: center !important;
	background-repeat: no-repeat !important;

	@include breakpoint(small) {

		margin-bottom: 0.5rem !important;
	}

	@include breakpoint(medium) {

		max-width: 24.25% !important;
		margin-bottom: 1%;
		
		&:nth-child(4n) {
			
			margin-right: 0px;
		}
	}

	.image {

		position: absolute;
		width: 200px;
		height: 200px;
		-webkit-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		background-repeat: no-repeat;
		left: 50%;
		top: 50%;
		background-size: contain;
		background-position: center center;
	}

	.gubbins {

		position: absolute;
		bottom: 1rem;
		left: 0;
		width: 70%;
		margin: 0 auot;
		left: 50%;
		transform: translate(-50%,0);
	}

	input {

		background: none;
		border: none;
		border-bottom: 1px solid white;
		box-shadow: inset 0px 0px 0px 0px red;
		color: white;
		font-size: 2rem;
		text-transform: capitalize;
		text-align:center;
		padding: 0px;
		font-weight: 200;

		&:focus {
			
			background: none;
			background: none;
			border: none;
			border-bottom: 1px solid white;
			box-shadow: inset 0px 0px 0px 0px red;
			color: white;
			font-size: 2rem;
		}
	}

	input::placeholder{
		
		color:white;
		font-weight: 200;
	}

	.close-button{
		
		top:0px;
	}

	.tools {

		padding:0rem;
		color: white;
		font-size: 2rem;

		.movie {

			background: url('/assets/img/film.svg');
			height: 30px;
			width: 30px;
			background-size: contain;
			background-repeat: no-repeat;
		}
		
		.tv {
			
			background: url('/assets/img/tv.svg');
			height: 30px;
			width: 30px;
			background-size: contain;
			background-repeat: no-repeat;
		}
	
		.type {
			
			float:left;
			line-height: 0px;
			
			img {
				
				height:33px;
			}
		}

		button {

			color: white;
			float:right;
			margin-top: -3px !important;

			& > span img {

				height: 26px;
			}
		}

		.has-tip {

			border-bottom: white;
		}
	}

	.notification {
		
		width: 100%;
		text-align: center;
		padding: .5rem 0;
		display: none;
	}

	.disable {
	
		position: absolute;
		z-index: 777;
		background: rgba(00,00,00, 0.5);
		width: 100%;
		visibility: hidden;
		opacity:0;
		transition: all .2s ease;
		left:0px;
		top:0px;
	}

	.disable::before{

		content: "";
		width: 100px;
		height: 100px;
		background: url("/assets/img/tick.svg") no-repeat;
		background-size: contain;
		top: 50%;
		left: 50%;
		position: absolute;
		display: inline-block;
		position: absolute;
		transform: translate(-50%, -50%);
	}

	.hint {
		
		position: absolute;
		z-index: 777;
		background: red;
		width: 100%;
		transition: all .2s ease;
		color: white;
		text-align: center;
		overflow: hidden;
		opacity:0;
		left:0px;
		top:0px;
		font-style: italic;
		
		p {
	
			transform: translate(-50%, -50%);
			position: absolute;
			top: 50%;
			left: 50%;
			font-size:2rem;
			font-weight: 200;
			width: 80%;
		}
	
		span {

			font-size: 3rem;
			color: white;
		}
	}

	.show {

		opacity: 1;
		visibility: visible;
		height: 100%;
	}

	.progress-meter {
	
		transition: all .2s ease;
		display: none;
	}

	.progress {
	
		display: none;
	}
}

footer {

	background: #532182;
	position: fixed;
	bottom: 0px;
	width: 100%;
	text-align: center;
	z-index: 999;

	@include breakpoint(small) {

		padding: 1rem 0;
	}

	@include breakpoint(small) {

		padding: 1.5rem 0;
	}

	.timer, 
	#totaliser {

		font-weight: 600;
		border-width: 5px;
		border-style: solid;
		border-radius:5px;
		display: inline-block;
		padding: .25rem 0.5rem;
		margin: 0 0 0 2rem;

		@include breakpoint(medium) {
			
			font-size: 1.2rem;
		}

		@include breakpoint(medium) {
			
			font-size: 1.8rem;
		}

		span.title {

			color: white;
		}

		div {
		
			display: inline-block;
			padding: 0rem;
		}
	}

	.timer {
		
		border-color: #fd5e60;
		color: #fd5e60;
		margin-left: 0!important;
	}

	#totaliser {
		
		border-color: #97c82b;
		color:  #fd5e60;
		
		#score {
			
			color: #97c82b;
		}
	}
}

div[data-sticky-container] {

	position: absolute!important;
	top: 0; left: -80px;
	width: 56px;
	position: relative;
	height: 1800px!important;

	@include breakpoint(small) {

		
	}

	@include breakpoint(large) {

		display: block;
	}
}

#sharing-is-caring {

	padding: 15px 10px;
	background-color: #854E9E;
	border-radius: 10px;
	width: 56px;
}

.social-icons {

	list-style: none;
	margin: 0;
	padding: 0;
	text-align: center;
}

.social-row {

	li {

		display: inline-block;
		margin-right: 1rem;

		&:last-of-type {

			margin-right: 0;
		}

		a img {

			width: 45px;
		}
	}
}

.social-stack {

	li {

		margin-bottom: 0.75rem;

		&:last-of-type {

			margin-bottom: 0;
		}

		a img {

			width: 36px;
			height: 36px;
		}
	}
}

.footer-socials {

	width: 100%;
	padding: 2rem 0;

	@include breakpoint(small medium) {

		display: block;
	}

	@include breakpoint(large) {

		display: none;
	}

	h4 {

		font-weight: 800;
		font-size: 1.6rem;
	}
}

#embed-code {

	opacity: 0;
	height: 0;
}

#embed .button {

	border-radius: 5px;
	font-weight: 600;
	background-color: #854E9E!important;
	margin-bottom: 0!important;

	&:hover {

		background-color: darken(#854E9E,10%)!important;
	}
}